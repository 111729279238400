import { Link } from "react-router-dom";
import "./css/index.scss";
const AccountSucess = ()=>{
    return (
        <main className="sucess-main-page">
            <div className="wrapper">
                <div className="img-container">
                    <img src="/assets/home/sangdaelogo.png"
                        srcSet='
                            /assets/home/sangdaelogo@2x.png 2x,
                            /assets/home/sangdaelogo@3x.png 3x'
                        alt="greenage" />
                </div>
                <div className="text-container">
                    <p className="title"> {`상대온천
회원가입이 완료되었습니다`}</p>
                    <p className="subtitle">
                        {`회원가입이 완료되었습니다.
상대온천의 더 많은 혜택을 만나보세요`}
                    </p>
                </div>
                <div className="mainbtn-container">
                    <div className="kakaotalk-btn">
                        <a href=" https://pf.kakao.com/_hbndG">
                            <img src="/assets/home/kakaobtn.png"
                                srcSet='
                            /assets/home/kakaobtn@2x.png ,
                            /assets/home/kakaobtn@3x.png '
                                alt="greenage" />
                        </a>
                    </div>
                    <div className="btn-container">
                        <Link to='/' className="main-button">메인으로</Link>
                    </div>
                </div>
                </div>
            
        </main>
    )
}

export default AccountSucess;