import React from 'react';
import { BrowserRouter, Routes,Route } from 'react-router-dom';
import HomeContainer from './pages/Home/Containers/HomeContainer';
import AccountSucess from './pages/Sucess/AccountSucess';
function App() {
  return (
    <div className='App'> 
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomeContainer />} />
          <Route path='/account/sucess' element={<AccountSucess />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
