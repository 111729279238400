import React, { useCallback, useState } from "react";
import Home from "../Home";
import {
  child,
  equalTo,
  getDatabase,
  orderByChild,
  push,
  query,
  ref,
  get,
} from "firebase/database";
import { fdb } from "../../../config/firebase";
import { useNavigate } from "react-router";
export interface ErrorState {
  id: string;
  name: string;
  phone: string;
  birthDate: string;
  gender: string;
}
export type UserInfo = {
  id: string;
  name: string;
  phone: string;
  gender: "man" | "girl" | undefined;
  birthDate: string;
};
const HomeContainer = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState<UserInfo>({
    id: "",
    name: "",
    phone: "",
    gender: undefined,
    birthDate: "",
  });
  const [check, setCheck] = useState<boolean>(false);

  const [error, setError] = useState<ErrorState>({
    id: "",
    name: "",
    phone: "",
    birthDate: "",
    gender: "",
  });
  const formatPhoneNumber = (value: string) => {
    const numbers = value.replace(/[^\d]/g, "");
    let phoneFormatted = "";

    if (numbers.length <= 3) {
      phoneFormatted = numbers;
    } else if (numbers.length <= 7) {
      phoneFormatted = `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}`;
    } else {
      phoneFormatted = `${numbers.slice(0, 3)}-${numbers.slice(
        3,
        7
      )}-${numbers.slice(7, 11)}`;
    }
    return phoneFormatted;
  };

  const UpdateData = useCallback((type: string, value: any) => {
    setdata((data) => {
      let obj = Object.assign({}, data);
      if (type === "phone") {
        obj.phone = formatPhoneNumber(value);
        return obj;
      }
      if (type === "birth") {
        if (value.length <= 8) {
          obj.birthDate = value;
        }
      }
      if (type === "name") {
        obj.name = value;
      }
      if (type === "gender") {
        obj.gender = value;
      }
      if (type === "gender") {
        obj.gender = value;
      }
      if (type === "id") {
        obj.id = value;
      }
      if (type === "check") {
        setCheck(value);
      }

      return obj;
    });
  }, []);
  const AllInputFilled = (): boolean => {
    return (
      data.id.trim() !== "" &&
      data.name.trim() !== "" &&
      data.phone.trim() !== "" &&
      data.birthDate.trim() !== "" &&
      data.name.length >= 2 &&
      data.name.length <= 6 &&
      data.phone.length === 13 &&
      data.birthDate.length === 8 &&
      data.gender !== undefined &&
      data.gender.length > 0 &&
      check
    );
  };
  const AccountSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (AllInputFilled()) {
      const dbRef = query(
        ref(fdb, "/users"),
        orderByChild("phone"),
        equalTo(data.phone)
      );
      const numberRef = query(
        ref(fdb, "/users"),
        orderByChild("id"),
        equalTo(data.id)
      );
      const duplicate = await Promise.all([
        await get(dbRef).then((snapshot) => snapshot.exists()),
        await get(numberRef).then((snapshot) => snapshot.exists()),
      ]);

      if (duplicate.every((value) => !value)) {
        get(ref(fdb, "/users")).then((result) => {
          const dataLength = Object.keys(result.val()).length;
          try {
            push(ref(fdb, "/users"), {
              id: data.id,
              name: data.name,
              phone: data.phone,
              birth: data.birthDate,
              gender: data.gender,
              agreement: check,
              timestamp: Date.now(),
              number: dataLength + 1,
            });
            navigate("/account/sucess");
          } catch (error) {
            console.log("error", error);
          }
        });
      } else {
        setError((prevState) => ({
          ...prevState,
          phone: duplicate[0] ? "이미 가입된 휴대전화입니다" : "",
          id: duplicate[1] ? "이미 사용 중인 고유번호입니다" : "",
        }));
      }
    } else {
      const newErrors = {
        id: data.id.length <= 0 ? "고유번호를 입력해주세요" : "",
        name:
          data.name.length <= 0 || data.name.length < 2
            ? "이름을 입력해주세요"
            : "",
        phone: data.phone.length <= 0 ? "휴대전화를 입력해주세요" : "",
        birthDate: data.birthDate.length <= 0 ? "생년월일을 입력해주세요" : "",
        gender:
          data.gender === undefined || data.gender?.length < 0
            ? "성별을 입력하세요"
            : "",
      };
      setError(newErrors);
    }
  };

  return (
    <Home
      data={data}
      check={check}
      AccountSubmit={AccountSubmit}
      setCheck={setCheck}
      AllInputFilled={AllInputFilled}
      UpdateData={UpdateData}
      error={error}
    />
  );
};
export default HomeContainer;
