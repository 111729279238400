import "./css/index.css";

type Props = {
    title: string;
    type: 'button' | 'reset' | undefined;
    isCheck: boolean;
    valueClick: (type:string,value:string) => void;
    value:string;
    dispatch:string;
};

const Button = ({ title, type, isCheck, valueClick,dispatch,value }: Props) => {
    return (
        <button
            className={`
                gender-choice ${isCheck && 'gender-choice-check'}
            `}
            type={type}
            onClick={()=>{
                valueClick(dispatch,value)
            }}
        >
            <div className="check-box" >
                {isCheck && <img src='/assets/home/check.svg' />}
            </div>
            {title}
        </button>
    )
}

export default Button;